import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableTextBlock from '../components/PortableTextBlock';
import Seo from '../components/Seo';

export const query = graphql`
	query articleQuery($_id: String!) {
		article: sanityArticle(_id: { eq: $_id }) {
			_id
			title
			seo {
				...seoFields
			}
			categories {
				title
			}
			tileImage {
				asset {
					gatsbyImageData(
						layout: FULL_WIDTH
						fit: FILLMAX
						placeholder: DOMINANT_COLOR
					)
				}
			}
			_rawArticleContent
		}
	}
`;

const ArticleTemplate = ({ data, pageContext }) => {
	const article = data && data.article;

	return (
		<>
			<Seo
				metaTitle={article.seo?.metaTitle || article.title}
				shareTitle={article.seo?.shareTitle || article.title}
				metaDesc={article.seo?.metaDesc}
				shareDesc={article.seo?.shareDesc}
			/>
			<div className="prose mb-4 lg:mb-8">
				<h1 className="h2 mr-4">{article.title}</h1>
				<p className="uppercase text-xl">
					{article.categories.map((cat) => cat.title).join(', ')}
				</p>
			</div>

			<div className="aspect-landscape relative overflow-hidden mb-8">
				<GatsbyImage
					image={article.tileImage.asset.gatsbyImageData}
					alt={article.title + ' article image'}
					className="absolute inset-0 h-full w-full object-cover z-0"
				/>
			</div>
			<div className="max-w-4xl w-full mb-4 lg:mb-8">
				<PortableTextBlock value={article._rawArticleContent || []} />
			</div>
			{pageContext.next && (
				<section className="border-t border-black pt-4 md:pt-8 prose">
					<h2>
						<Link to={'/articles/' + pageContext.next.slug.current}>
							Next article - {pageContext.next.title}
						</Link>
					</h2>
					<Link to={'/articles/' + pageContext.next.slug.current}>
						<div className="aspect-landscape relative overflow-hidden mb-4 md:mb-8">
							<GatsbyImage
								image={
									pageContext.next.tileImage.asset
										.gatsbyImageData
								}
								alt={pageContext.next.title + ' project image'}
								className="absolute inset-0 h-full w-full object-cover z-0"
							/>
						</div>
					</Link>
				</section>
			)}
		</>
	);
};

export default ArticleTemplate;
